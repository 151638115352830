
.login-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-image: url("../assets/img/image/admin-bg.png");
    background-repeat: no-repeat;
    /*background-size: contain;*/
    overflow: auto;
}
.header {
    padding: 20px;
}
.content {
    width: 960px;
    height:520px;
    margin: 40px auto;
}
.left {
    width: 50%;
    height: 100%;
    float: left;
    background-image: url("../assets/img/image/admin-left.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 4px 0 0 4px;
}
.right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    float: right;
    background-color: #fff;
    border-radius: 0 4px 4px 0;
    .el-button--primary{
        background-color: #1E34E4;
        border-color: #1E34E4;
    }
    .el-button--primary:focus, .el-button--primary:hover {
        background:#4154ea ;
        border-color: #4154ea;
        color: #FFF;
    }
}
.login-form {
    width: 400px;
    height:300px;
    margin: 0 auto;
    ::v-deep .el-input__inner {
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #DCDFE6;
    }

    ::v-deep.el-form-item__content{
        margin-left: 0!important;
    }
    i{
        font-size: 20px;
    }
}
::v-deep.el-form-item{
    margin-bottom: 30px;
}
.title {
    display: flex;
    justify-content: center;
    margin-bottom:30px;
}
.title span {
    font-size: 24px;
    color: #4154ea;
    margin-left: 10px;
}
.admin-btn{
    width:100%;
    /*margin-left: 68px;*/
    margin-top: 30px;
}
